import { useLocation } from "react-router";
import {
  getOqbFetchError,
  getOqbQuestionPropertiesByIDRequest,
} from "pages/build-test/slices/fetch-oqb-question-slice";
import {
  viewQuestionRequest,
  viewQuestionSuccess,
} from "pages/build-test/slices/view-question-details";
import { takeLatest, put, call, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import libraryService from "./service";
import { QUESTION_TYPES } from "./routes/question-grouping/helpers";
import { ResponseGenerator } from "interfaces";
import { goBack, replace } from "connected-react-router";
import * as selectors from "./selectors";
import {
  getBuildTestListError,
  getBuildTestListRequest,
  getBuildTestListSuccess,
} from "./slices/buil-test-list-slice";
import {
  getBuildDeliveryListError,
  getBuildDeliveryListRequest,
  getBuildDeliveryListSuccess,
} from "./slices/build-delivery-list-slice";
import {
  createBuildDeliveryRequest,
  createBuildDeliverySuccess,
} from "./slices/create-build-delivery-slice";
import {
  createBuildTestRequest,
  createBuildTestSuccess,
} from "./slices/create-build-test-slice";
import {
  getAnswerKeyRequest,
  getAnswerKeySuccess,
  getAnswerKeyError,
} from "pages/build-test/slices/get-answer-key";
import {
  getPreviewDataRequest,
  getPreviewDataSuccess,
  getPreviewDataError,
} from "pages/build-test/slices/preview-slice";
import {
  devliveryByIdRequest,
  devliveryByIdSuccess,
} from "pages/build-test/slices/get-delivery-by-id";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import {
  validateWarmUpDeliveryIdRequest,
  validateWarmUpDeliveryIdSuccess,
} from "./slices/validate-warm-up-slice";
import {
  refreshDeliveryRequest,
  refreshDeliverySuccess,
} from "./slices/refresh-delivery-request";
import {
  updateBuildTestRequest,
  updateBuildTestSuccess,
} from "pages/build-test/slices/update-build-test-slice";
import {
  getTemplateListSuccess,
  getTemplateListRequest,
  getTemplateListError,
} from "./slices/get-template-list-slice";
import {
  getTemplateDetailsRequest,
  getTemplateDetailsSuccess,
} from "./slices/get-template-details";
import {
  getTestDetailsByIdRequest,
  getTestDetailsByIdSuccess,
} from "./slices/test-details-by-id-slice";
import {
  questionGroupItemListRequest,
  questionGroupItemListSuccess,
} from "./slices/question-group-item-list-slice";
import {
  saveSectionWiseQuesionsRequest,
  saveSectionWiseQuesionsSuccess,
  saveSectionWiseQuesionsError,
  saveSectionWiseQuesionsErrorMessage,
} from "./slices/save-section-wise-questions";
import {
  normalizeQuestionRes,
  normalizeGroupRes,
  normalizeSubGroupResponse,
  normalizeQuestionListResponse,
  getHighestValue,
} from "./helpers";
import {
  getQuestionGroupListRequest,
  getQuestionGroupListSuccess,
  getQuestionListLoadingStatus,
} from "./slices/get-question-group-list";
import {
  getSubQuestionGroupItemListRequest,
  getSubQuestionGroupItemListSuccess,
  updateQuestionById,
} from "./slices/get-sub-question-item-list";
import {
  createSubQuestionGroupRequest,
  createSubQuestionGroupSuccess,
} from "pages/build-test/slices/create-sub-question-group";
import {
  createSubQuestionGroupItemRequest,
  createSubQuestionGroupItemSuccess,
} from "pages/build-test/slices/create-sub-question-group-item-slice";
import {
  updateQuestionGroupRequest,
  updateQuestionGroupSuccess,
} from "pages/build-test/slices/update-question-group-slice";
import {
  updateSubQuestionGroupRequest,
  updateSubQuestionGroupSuccess,
} from "pages/build-test/slices/update-sub-question-group-id-slice";
import {
  addContentRequest,
  addContentSuccess,
  addContentError,
  addContentSuccessMessage,
} from "pages/build-test/slices/add-content-slice";
import {
  addNewSubGroup,
  getQuestionGroupListDetailsByIDRequest,
  getQuestionGroupListDetailsByIDSuccess,
} from "./slices/get-question-group-list-details-by-id";
import {
  updateSubQuestionGroupItemRequest,
  updateSubQuestionGroupItemSuccess,
} from "./slices/update-sub-question-group-item";
import {
  createQuestionGroupRequest,
  createQuestionGroupSuccess,
} from "./slices/create-question-group-slice";
import {
  getQuestionDataByIdRequest,
  getQuestionDataByIdSuccess,
  setAnswerKey,
  setChoices,
  setGridChoiceById,
  setGridChoiceCount,
  updateGridRowsAndColumns,
  updateChoices,
  resetCreateQuestion,
} from "pages/build-test/slices/question-creation-slice";
import {
  updateQuestionData,
  updateOptions,
  updateNumericValues,
  updateAnswerKey,
  resetEditorValues,
  setClientPreviewData,
  setExplanation,
  setHint,
} from "pages/build-test/slices/question-editors-slice";
import { getQueryParam } from "utils/helpers";
import { getOqbQuestionPropertiesByIDSuccess } from "pages/build-test/slices/fetch-oqb-question-slice";
import {
  updateSubQuestions,
  updateChoiceCount,
  updateOptions as updateParagraphOptions,
} from "./slices/paragraph-question";
import {
  deleteSubQuestionGroupRequest,
  deleteSubQuestionGroupSuccess,
} from "./slices/delete-sub-question-group";

function* getBuildTestListWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getBuildTestListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(getBuildTestListSuccess(res.data.data));
    } else {
      yield put(getBuildTestListError(res.data.message));
    }
  } catch (e) {}
}

function* getBuildDeliveryListWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getBuildDeliveryListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(getBuildDeliveryListSuccess(res.data.data));
    } else {
      yield put(getBuildDeliveryListError(res.data.message));
    }
  } catch (e) {}
}

function* createBuildDeliveryWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.createBuildDeliveryApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: "Delivery created successfully" }));
      const { payload } = yield put(createBuildDeliverySuccess(res.data.data));
      if (payload.delivery_id) {
        const reqBody: any = JSON.parse(
          sessionStorage.getItem("buildTestFilterData") || "{}"
        );
        yield put(getBuildDeliveryListRequest(reqBody));
      }
      yield put(replace(`/build-test/delivery`));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* createBuildTestWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.createBuildTestApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: "crated successfully" }));
      yield put(createBuildTestSuccess(res.data.data));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* getAnswerKeytWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getAnswerKey,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(getAnswerKeySuccess(res.data.data));
    } else {
      yield put(getAnswerKeyError(res.data));
    }
  } catch (e) {}
}

function* getDeliveryByIdWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getDeliveryById,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(devliveryByIdSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getPreviewDataWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getTestPreviewData,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(getPreviewDataSuccess(res.data.data));
    } else {
      yield put(getPreviewDataError(res.data.message));
    }
  } catch (e) {}
}

function* validateWarmUpDeliveryId(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getValidateWarmUp,
      action.payload
    );
    if (
      res.status === 200 &&
      res.data.status === "success" &&
      res.data.data.is_valid_delivery === true
    ) {
      yield put(
        validateWarmUpDeliveryIdSuccess(res.data.data.is_valid_delivery)
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message }));
    }
  } catch (e) {}
}

function* refreshDeliveryRequestWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.refreshDelivery,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(refreshDeliverySuccess(res.data.data));
      yield put(replace(`/build-test/delivery`));
      yield put(
        snackbarRequest({ message: "Delivery Refreshed Successfully" })
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* updateBuildTestWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.updateTestForm,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(updateBuildTestSuccess(res.data.data));
      // yield put(replace(`/build-test`));
      yield put(snackbarRequest({ message: res.data.message }));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* templateListWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getTemplateList,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(getTemplateListSuccess(res.data.data));
    } else {
      yield put(getTemplateListError(res.data.message));
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* templateDetailsWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getTemplateDetails,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(getTemplateDetailsSuccess(res.data.data));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* getTestDetailsByIdWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getTestDetailsById,
      action.payload
    );
    // const res: any = dummyTestResponse;
    if (res.status === 200 && res.data.status === "success") {
      const normalizedSegmentDetails = normalizeQuestionRes(
        res?.data?.data?.segment_list
      );
      yield put(
        getTestDetailsByIdSuccess({
          ...normalizedSegmentDetails,
          apiResponse: res?.data?.data,
        })
      );
    }
  } catch (e) {}
}

function* questionGroupItemListWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getQuestionGroupItemList,
      action.payload
    );
    // const res: any = dummyQuestionsResponse;
    if (res.status === 200 && res.data.status === "success") {
      const normalizedQuestionGroups = normalizeGroupRes(
        res?.data?.data?.question_group_list
      );
      yield put(
        questionGroupItemListSuccess({
          ...normalizedQuestionGroups,
          apiResponse: res?.data?.data,
        })
      );
    }
  } catch (e) {}
}

function* saveSectionWiseQuestionsWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.saveSectionWiseQuestions,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      snackbarRequest({ message: res.data.message, type: "success" });
      yield put(replace("/build-test/tests"));
    } else {
      if (Array.isArray(res?.data?.data)) action.payload.cb(res.data.data);
      else action.payload.cb([res.data.message]);
      yield put(saveSectionWiseQuesionsError(res.data.status));
      yield put(saveSectionWiseQuesionsErrorMessage(res.data.message));
    }
  } catch (e) {}
}

function* viewQuestionworker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.viewQuestionDetails,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(viewQuestionSuccess(res.data.data));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* getQuestionGroupListWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getQuestionGroupList,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      yield put(getQuestionGroupListSuccess(res.data.data));
    } else {
      yield put(getQuestionListLoadingStatus(false));
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch {}
}

function* getSubQuestionGroupItemListWorker(action: PayloadAction<any>) {
  try {
    if (action.payload.sub_question_group_id) {
      const res: ResponseGenerator = yield call(
        libraryService.getSubQuestionGroupItemList,
        action.payload
      );
      if (res.status == 200 && res.data.status == "success") {
        const { questionById, questionList } = normalizeQuestionListResponse(
          res.data.data
        );
        yield put(
          getSubQuestionGroupItemListSuccess({
            data: res.data.data,
            questionById,
            questionList,
          })
        );
      } else {
        yield put(
          snackbarRequest({ message: res.data.message, type: "error" })
        );
      }
    } else {
      const { questionById, questionList } = normalizeQuestionListResponse("");
      yield put(
        getSubQuestionGroupItemListSuccess([
          { data: {}, questionById, questionList },
        ])
      );
    }
  } catch {}
}

function* createSubQuestionGroupWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.createSubQuestionGroup,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      const resData = res.data.data;
      const { subGroupList, subGroupById } = yield select(
        selectors.questionGroupDetailsSelector
      );
      const tempSubGroupById = {};
      const tempSubGroupId = action.payload.temp_sub_group_id;

      subGroupList.map((item) => {
        if (item === tempSubGroupId) {
          tempSubGroupById[tempSubGroupId] = {
            tabId: tempSubGroupId,
            sub_question_group_id: resData.sub_question_group_id,
            total_questions_count:
              subGroupById[tempSubGroupId].total_questions_count,
            sub_question_group_name: action.payload.sub_question_group_name,
            isNewGroup: false,
          };
        } else
          tempSubGroupById[item] = {
            ...subGroupById[item],
          };
      });
      yield put(
        addNewSubGroup({
          subGroupList,
          subGroupById: tempSubGroupById,
        })
      );
      yield put(createSubQuestionGroupSuccess(res.data.data));
      yield put(
        snackbarRequest({ message: "Sub Question Group Created Successfully" })
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch {}
}

function* updateQuestionGroupWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.updateQuestionGroup,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      yield put(updateQuestionGroupSuccess(res.data.data));
      action?.payload?.successCallback({
        ...res.data.data,
        question_group_name: action.payload.question_group_name,
        tentative_date: action.payload.tentative_date,
        comments: action.payload.comments,
        question_group_id: action.payload.question_group_id,
      });
      yield put(snackbarRequest({ message: res.data.message }));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch {}
}

function* updateSubQuestionGroupWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.updateSubQuestionGroup,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      yield put(updateSubQuestionGroupSuccess(res.data.data));
      yield put(
        snackbarRequest({
          message: "Sub Question Group Name Updated Successfully",
        })
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch {}
}

const getOptionList = (options, type) => {
  if (
    [QUESTION_TYPES.SINGLE_SELECT, QUESTION_TYPES.MULTI_SELECT].includes(type)
  )
    return Object?.entries(options)?.map((item, index) => ({
      option_id: `choice_${index + 1}`,
      option_data: options[`choice_${index + 1}`],
    }));
  return options;
};

const getAnswerKey = (answerKey, questionType) => {
  if ([QUESTION_TYPES.MULTI_SELECT, QUESTION_TYPES.GRID].includes(questionType))
    return answerKey.join(";");
  return answerKey;
};

const getParagraphReqBody = async (
  payload,
  paragraphQuestionsSlice,
  questionEditor
) => {
  const questionType = payload?.questionType?.replaceAll("%20", " ");
  const subQuestionType = await sessionStorage.getItem("paragraphQuestionType");
  const { subQuestions, optionById } = paragraphQuestionsSlice;
  let answerKey = [];
  let responses: any = [];
  let subQuestionsList = await Object.values(subQuestions).map(
    (item, idx): any => {
      const isMultiSelect = QUESTION_TYPES.MULTI_SELECT === subQuestionType;
      const isNumeric = QUESTION_TYPES.NUMERIC === subQuestionType;
      // @ts-ignore
      const ans = isMultiSelect ? item.answer.join(";") : item.answer;
      // @ts-ignore
      answerKey.push(ans);
      const response = idx === 0 ? `RESPONSE` : `RESPONSE_${idx}`;
      responses.push(response);

      let subQuest = {
        type: subQuestionType,
        response_identifier: response,
        // @ts-ignore
        answer_key: isMultiSelect ? item.answer.join(";") : item.answer,
        // @ts-ignore
        question_data: item.questionData?.local,
        // @ts-ignore
        option_list: item.choices.map((ch) => ({
          option_id: ch,
          option_data: optionById[ch]?.local,
        })),
        // @ts-ignore
        explanation: item.explanation || "",
        // @ts-ignore
        hint: item.hint || "",
      };
      if (isNumeric) {
        // @ts-ignore
        subQuest["pattern_mask"] = item.patternMask;
        // @ts-ignore
        subQuest["higher_value"] = item.higherValue;
        // @ts-ignore
        subQuest["lower_value"] = item.lowerValue;
      }
      return subQuest;
    }
  );

  let reqBody = {
    item_uri: payload.testUri,
    question_id: payload.questionId,
    answer_key: answerKey.join(":"),
    response_identifier: responses.join(":"),
    title: payload.questionLabel,
    type: questionType,
    paragraph: questionEditor.questionData,
    sub_question_list: subQuestionsList,
  };
  return reqBody;
};

function* addContentWorker(action: PayloadAction<any>) {
  try {
    const createQuestion = yield select(selectors.createQuestionSelector);
    const questionEditor = yield select(selectors.questionEditorsSlice);
    const paragraphSelector = yield select(
      selectors.paragraphQuestionsSliceSelector
    );
    const questionType = action?.payload?.questionType?.replaceAll("%20", " ");//

    let reqBody: any = {};
    if (QUESTION_TYPES.PARAGRAPH === questionType) {
      reqBody = yield getParagraphReqBody(
        action.payload,
        paragraphSelector,
        questionEditor
      );
    } else {
      reqBody = {
        item_uri: action.payload.testUri,
        question_id: action.payload.questionId,
        answer_key: getAnswerKey(createQuestion.answerKey, questionType),
        response_identifier: createQuestion.response,
        title: action.payload.questionLabel,
        type: questionType,
        explanation: questionEditor.explanation,
        hint: questionEditor.hint,
        question_data: questionEditor.questionData,
        option_list: getOptionList(questionEditor.options, questionType),
      };
      if (QUESTION_TYPES.NUMERIC === questionType) {
        reqBody["pattern_mask"] = questionEditor.patternMask;
        reqBody["higher_value"] = questionEditor.highValue;
        reqBody["lower_value"] = questionEditor.lowValue;
        reqBody["option_list"] = {}
      } else if (QUESTION_TYPES.GRID === questionType) {
        const { gridRowsAndColumns, choiceById } = createQuestion;
        reqBody["option_list"] = [];
        reqBody["rows"] = gridRowsAndColumns.rows.map((row) => ({
          option_id: row,
          data: choiceById[row],
        }));
        reqBody["columns"] = gridRowsAndColumns.columns.map((column) => ({
          option_id: column,
          data: choiceById[column],
        }));
      }
    }

    const res: ResponseGenerator = yield call(
      libraryService.addQuestionContent,
      reqBody
    );

    if (res.status == 200 && res.data.status == "success") {
      const { subQuestionGroupId, activeTabId, questionGroupId } =
        action.payload;
      yield put(setClientPreviewData([]));
      yield put(addContentSuccess(res.data.data));
      yield put(snackbarRequest({ message: res.data.message }));
      yield put(addContentSuccessMessage(res));
      yield put(
        replace({
          pathname: `/build-test/question-group/${questionGroupId}`,
          search: `?activeSubGroup=${subQuestionGroupId}`,
        })
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
      yield put(addContentError(res.data.message));
    }
  } catch (err) {
    yield put(addContentError(err));
  }
}

const getSubQuestions = (list) => {
  if (!list || !list.length) return [];
  const subQuestionType = list[0].type;

  sessionStorage.setItem("paragraphQuestionType", subQuestionType);
  const isMultiSelect = QUESTION_TYPES.MULTI_SELECT === subQuestionType;
  let optById = {};
  const subQuestions = list.reduce((acc, item) => {
    const choices = item?.option_list?.map((ch) => {
      optById[ch.option_id] = {
        apiData: ch.option_data,
        local: ch.option_data,
      };
      return ch.option_id;
    });
    const timeStamp = Math.floor(new Date().getTime() * Math.random());

    return {
      ...acc,
      [timeStamp]: {
        answer: isMultiSelect ? item.answer_key.split(";") : item.answer_key,
        higherValue: item.higher_value,
        lowerValue: item.lower_value,
        patternMask: item.pattern_mask,
        questionData: {
          apiData: item.question_data,
          local: item.question_data,
        },
        response: item.response_identifier,
        choices: choices,
        type: item.type,
        explanation: item.explanation,
        hint: item.hint,
      },
    };
  }, {});

  return { subQuestions, optById, choiceCount: Object.keys(optById).length };
};

function* getQuestionDataWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getQuestionDetailsByOqbId,
      action.payload
    );

    if (res.status === 200 && res.data.status == "success") {
      const resData = res.data.data;
      if (!resData?.is_content_exist && action.payload.source !== "preview") {
        yield put(updateSubQuestions({}));
      }
      if (action.payload.source == "preview") {
        yield put(getQuestionDataByIdSuccess(resData));
      } else if (resData.question_content) {
        const questionContent = resData?.question_content;
        let answerKey =
          questionContent?.type === QUESTION_TYPES.MULTI_SELECT
            ? questionContent?.answer_key?.split(";")
            : questionContent?.answer_key;
            if(action.payload?.questionType == questionContent?.type){
              yield put(setAnswerKey(answerKey));
            }else{
              yield put(setAnswerKey(""));
            }

        if (
          [QUESTION_TYPES.SINGLE_SELECT, QUESTION_TYPES.MULTI_SELECT].includes(
            resData?.question_content?.type
          )
        ) {
          const choices = resData.question_content?.option_list.reduce(
            (acc, curr) => ({ ...acc, [curr.option_id]: curr.option_data }),
            {}
          );
          yield put(updateOptions(choices));
          yield put(setChoices(Object.keys(choices)));
          yield put(updateAnswerKey(answerKey));
          // yield put(updateChoices(Object.keys(choices)))
          yield put(setExplanation(questionContent.explanation));
          yield put(setHint(questionContent.hint));
          yield put(getQuestionDataByIdSuccess({ ...resData, choices }));
        } else if (QUESTION_TYPES.NUMERIC === questionContent.type) {
          const { higher_value, lower_value, pattern_mask } =
            resData.question_content;
          yield put(
            updateNumericValues({
              highValue: higher_value,
              lowValue: lower_value,
              patternMask: pattern_mask,
            })
          );
          yield put(setExplanation(questionContent.explanation));
          yield put(setHint(questionContent.hint));
          yield put(updateAnswerKey(answerKey));
          yield put(getQuestionDataByIdSuccess(resData));
        } else if (QUESTION_TYPES.PARAGRAPH === questionContent.type) {
          const subQuestionList = questionContent.sub_question_list;
          const subQuestionsDetails = yield getSubQuestions(subQuestionList);
          const highestChoiceValue = yield getHighestValue(
            Object.keys(subQuestionsDetails.optById)
          );
          yield put(updateSubQuestions(subQuestionsDetails.subQuestions));
          yield put(updateParagraphOptions(subQuestionsDetails.optById));
          yield put(updateChoiceCount(highestChoiceValue));
          yield put(getQuestionDataByIdSuccess(resData));
        } else if (QUESTION_TYPES.GRID === questionContent.type) {
          const chById = {};
          questionContent.columns.map((item) => {
            chById[item.option_id] = item.data;
          });
          questionContent.rows.map((item) => {
            chById[item.option_id] = item.data;
          });
          const rowsAndColumns = {
            rows: questionContent.rows.map((item) => item.option_id),
            columns: questionContent.columns.map((item) => item.option_id),
          };
          const highestChoiceValue = yield getHighestValue(Object.keys(chById));
          if(action.payload?.questionType == questionContent?.type){
            yield put(setAnswerKey(answerKey.split(";")));
            yield put(updateAnswerKey(answerKey.split(";")));
          }
          yield put(setGridChoiceById(chById));
          yield put(setExplanation(questionContent.explanation));
          yield put(setHint(questionContent.hint));
          yield put(setGridChoiceCount(highestChoiceValue));
          yield put(updateGridRowsAndColumns(rowsAndColumns));
          yield put(getQuestionDataByIdSuccess({ ...resData }));
        } else {
          yield put(getQuestionDataByIdSuccess(resData));
        }

        yield put(
          updateQuestionData(
            questionContent?.question_data || questionContent?.paragraph
          )
        );
      } else {
        yield put(resetEditorValues());
        yield put(resetCreateQuestion());
        yield put(getQuestionDataByIdSuccess({ ...resData }));
      }
    } else {
      yield put(snackbarRequest({ type: "error", message: res.data.message }));
    }
  } catch (error) {
    console.log("Something went wrong!", error);
  }
}

function* getQuestionGroupListDetailsByIDWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getQuestionGroupListDetailsById,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      const responseData = res.data.data;
      const { location } = yield select(selectors.router);
      const { subGroupList, subGroupById } = yield normalizeSubGroupResponse(
        responseData
      );
      const activeSubGroup = yield getQueryParam("activeSubGroup", location);
      const subGroupId = activeSubGroup
        ? subGroupList.includes(activeSubGroup)
          ? activeSubGroup
          : subGroupList[0]
        : subGroupList.length
        ? subGroupList[0]
        : "";
      yield put(
        replace({
          pathname: location.pathname,
          state: location.state,
          search: `?activeSubGroup=${subGroupId}`,
        })
      );
      yield put(
        getQuestionGroupListDetailsByIDSuccess({
          data: responseData,
          subGroupList,
          subGroupById,
        })
      );
      yield put(
        getSubQuestionGroupItemListRequest({
          question_group_id: action.payload.question_group_id,
          sub_question_group_id: subGroupId,
        })
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (err) {
    yield put(
      snackbarRequest({ message: "Something went wrong!", type: "error" })
    );
    console.log("Something went wrong!", err);
  }
}

function* createSubQuestionGroupItemWorker(action: PayloadAction<any>) {
  const { callback, ...rest } = action.payload;
  try {
    const res: ResponseGenerator = yield call(
      libraryService.createSubQuestionGroupItem,
      rest
    );

    if (res.status == 200 && res.data.status == "success") {
      const responseData = res.data.data;
      const { questionById } = yield select(
        selectors.subQuestionGroupItemListSelector
      );
      yield put(
        updateQuestionById({
          ...questionById,
          [rest.tempQuestionId]: {
            ...rest,
            question_id: responseData.question_id,
            item_uri: responseData.item_uri,
            isNewQuestion: false,
            isActive: true,
          },
        })
      );
      yield put(createSubQuestionGroupItemSuccess(res.data.data));
      yield put(snackbarRequest({ message: res.data.message }));
      callback("success");
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
      callback("fail");
    }
  } catch {}
}

function* updateSubQuestionGroupItemWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.updateSubQuestionGroupItem,
      action.payload
    );

    if (res.status == 200 && res.data.status == "success") {
      yield put(updateSubQuestionGroupItemSuccess(res.data.data));
      yield put(snackbarRequest({ message: res.data.message }));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch {}
}

function* createQuestionGroupWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.createQuestionGroup,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      yield put(createQuestionGroupSuccess(res.data.data));
      yield put(
        snackbarRequest({
          message: "Question Group Created Successfully",
        })
      );
      action?.payload?.successCallback({
        ...res.data.data,
        question_group_name: action.payload.question_group_name,
        tentative_date: action.payload.tentative_date,
        comments: action.payload.comments,
      });
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch {}
}

function* fetchOqbQuestionDetailsWorker(action: PayloadAction<any>) {
  const { question_id, formState } = action.payload;
  try {
    const res: ResponseGenerator = yield call(
      libraryService.fetOqbQuestionData,
      // { question_id: question_id }
      action.payload
    );

    if (res.status == 200 && res.data.status == "success") {
      // if (formState == "Add")
      // else
      yield put(getOqbQuestionPropertiesByIDSuccess(res.data.data));
      yield put(
        snackbarRequest({
          message: "Fetched Question Properties Successfully",
        })
      );
    } else {
      yield put(getOqbFetchError(res.data.message));
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch {}
}

function* deleteSubQuestionGroupWorker(action: PayloadAction<any>) {
  try {
    const { question_group_id, sub_question_group_id, callback } =
      action.payload;
    const res: ResponseGenerator = yield call(
      libraryService.deleteSubQuestionGroup,
      { question_group_id, sub_question_group_id }
    );
    if (
      res.status == 200 &&
      (res.data.status == "Success" || res.data.status == "success")
    ) {
      yield put(deleteSubQuestionGroupSuccess(res.data.data));
      yield put(
        snackbarRequest({
          message: "Sub Question Group Deleted Successfully",
        })
      );
      callback();
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch {}
}

export default function* librarysSaga() {
  yield takeLatest(getTestDetailsByIdRequest.type, getTestDetailsByIdWorker);
  yield takeLatest(
    saveSectionWiseQuesionsRequest.type,
    saveSectionWiseQuestionsWorker
  );
  yield takeLatest(
    questionGroupItemListRequest.type,
    questionGroupItemListWorker
  );
  yield takeLatest(getBuildTestListRequest.type, getBuildTestListWorker);
  yield takeLatest(
    getBuildDeliveryListRequest.type,
    getBuildDeliveryListWorker
  );
  yield takeLatest(createBuildDeliveryRequest.type, createBuildDeliveryWorker);
  yield takeLatest(getPreviewDataRequest.type, getPreviewDataWorker);
  yield takeLatest(createBuildTestRequest.type, createBuildTestWorker);
  yield takeLatest(getAnswerKeyRequest.type, getAnswerKeytWorker);
  yield takeLatest(devliveryByIdRequest.type, getDeliveryByIdWorker);
  yield takeLatest(
    validateWarmUpDeliveryIdRequest.type,
    validateWarmUpDeliveryId
  );
  yield takeLatest(refreshDeliveryRequest.type, refreshDeliveryRequestWorker);
  yield takeLatest(getTemplateListRequest.type, templateListWorker);
  yield takeLatest(getTemplateDetailsRequest.type, templateDetailsWorker);
  yield takeLatest(updateBuildTestRequest.type, updateBuildTestWorker);
  yield takeLatest(viewQuestionRequest.type, viewQuestionworker);
  yield takeLatest(
    getQuestionGroupListRequest.type,
    getQuestionGroupListWorker
  );
  yield takeLatest(
    getSubQuestionGroupItemListRequest.type,
    getSubQuestionGroupItemListWorker
  );
  yield takeLatest(deleteSubQuestionGroupRequest, deleteSubQuestionGroupWorker);
  yield takeLatest(
    createSubQuestionGroupRequest.type,
    createSubQuestionGroupWorker
  );
  yield takeLatest(updateQuestionGroupRequest.type, updateQuestionGroupWorker);
  yield takeLatest(
    updateSubQuestionGroupRequest.type,
    updateSubQuestionGroupWorker
  );

  yield takeLatest(addContentRequest.type, addContentWorker);
  yield takeLatest(
    getQuestionGroupListDetailsByIDRequest.type,
    getQuestionGroupListDetailsByIDWorker
  );
  yield takeLatest(
    createSubQuestionGroupItemRequest.type,
    createSubQuestionGroupItemWorker
  );
  yield takeLatest(
    updateSubQuestionGroupItemRequest.type,
    updateSubQuestionGroupItemWorker
  );
  yield takeLatest(createQuestionGroupRequest.type, createQuestionGroupWorker);
  yield takeLatest(getQuestionDataByIdRequest.type, getQuestionDataWorker);
  yield takeLatest(
    getOqbQuestionPropertiesByIDRequest.type,
    fetchOqbQuestionDetailsWorker
  );
}
